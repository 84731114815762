import axios from 'axios';
import { SwingvyUtil } from '@swingvy/frontend-util';
import RoutePath from 'js/app/RoutePath';
import Logger from 'js/helper/Logger';
import API from 'js/network/Api';
import { ERROR_CODE } from 'js/util/Constants';

export const handleSwingvyCoreError = ({ status, responseJSON }) => {
    let isErrorHandled = false;
    try {
        if (!status === 500) {
            return isErrorHandled;
        }
        const { code } = responseJSON;
        if (code === ERROR_CODE.INVALID_SESSION) {
            isErrorHandled = true;
            document.location.href = RoutePath.LOGOUT;
        } else if (code === ERROR_CODE.ACCESS_TOKEN_EXPIRED) {
            axios.post(API.REFRESH_TOKEN.url).then((result) => console.log(result));
        } else if (code === ERROR_CODE.SHOULD_MOVE_TO_SWITCH_ACCOUNT_PAGE) {
            isErrorHandled = true;
            document.location.replace(`${SwingvyUtil.getHrisHost()}/switch-account.html`);
        }
        return isErrorHandled;
    } catch (error) {
        Logger.log(error);
        return isErrorHandled;
    }
};

export const getRequestError = (jqXHR) => {
    return {
        status: jqXHR.status,
        message: jqXHR.responseJSON ? jqXHR.responseJSON.message : jqXHR.statusText,
        code: jqXHR.responseJSON ? jqXHR.responseJSON.code : null,
        data: jqXHR.responseJSON ? jqXHR.responseJSON.data : null,
    };
};
