import jQuery from 'jquery';
import 'jquery-migrate';
import 'jquery-ui-dist/jquery-ui';
import 'bootstrap';
import 'plugins/jquery-metadata/jquery.metadata.min';
import 'jquery-validation';
import 'bootstrap-multiselect';
import 'picker';
import 'pickadate/lib/picker.date';
import 'bootstrap-datepicker';
import 'bootstrap-daterangepicker';
import 'parsleyjs';
import 'select2/dist/js/select2.full';
import 'plugins/number/jquery.number';
import 'multiselect/js/jquery.multi-select';
import 'plugins/quicksearch/jquery.quicksearch';
import 'bootstrap-select';
import 'plugins/table-edit/jquery.tabledit';
import 'plugins/nestable/jquery.nestable';
import 'plugins/ionRangeSlider/ion.rangeSlider';
import 'datatables.net';
import 'datatables.net-bs';
import 'datatables.net-buttons';
import 'fullcalendar/dist/fullcalendar';
import 'fullcalendar/dist/lang-all';
import 'footable/dist/footable.all.min';
import 'jstree';

export default jQuery;
