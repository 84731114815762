import Handlebars from 'handlebars';

export const formatRoute = (route, options) => {
    const template = Handlebars.compile(route);
    return template(options);
};

const RoutePath = {
    LOGIN: '#/login',
    FORGOT_PASSWORD: '#/etc/forgotPassword',
    NEW_PASSWORD: '#/etc/newPassword',
    HOME: '#/home',
    FORBIDDEN: '#/forbidden',
    NEWS_ENTRY: '#/news',
    NEWS: {
        LIST: '#/news/list',
        VIEW: '#/news/view',
    },
    PEOPLE_ENTRY: '#/people',
    PEOPLE: {
        LIST: '#/people/list',
        ORG_CHART: '#/people/org_chart',
        PROFILE: '#/people/profile',
    },
    LEAVE_ENTRY: '#/leave',
    LEAVE: {
        REQUEST: '#/leave/request',
        APPROVAL: '#/leave/approval',
        BALANCE: '#/leave/balance',
        SETTING_ENTRY: '#/leave/setting',
        SETTING: {
            POLICY: '#/leave/setting/policy',
        },
    },
    LEAVE_APP: {
        ENTRY: '#/leave_app',
        ALL_LEAVE: '#/leave_app/all_leave',
        BALANCE: '#/leave_app/balance',
        REPORTS: '#/leave_app/reports',
        SETTINGS: {
            ENTRY: '#/leave_app/settings',
        },
        POLICY: {
            // deprecated. use SETTINGS instead
            ENTRY: '#/leave_app/policies',
        },
    },
    CLAIMS_ENTRY: '#/claims',
    CLAIMS: {
        MY_CLAIMS: '#/claims/my_claims',
        MY_CLAIMS_REPORT: '#/claims/my_claims/claim_report',
        APPROVALS: '#/claims/approvals',
        APPROVALS_REPORT: '#/claims/approvals/claim_report',
    },
    CALENDAR: '#/calendar',
    INVITE_FRIENDS: '#/invite_friends',
    TALENT_ENTRY: '#/talent',
    TALENT: {
        MY_GOALS: '#/talent/my_goals',
        REVIEW_GOALS: '#/talent/review_goals',
        SETTING: '#/talent/setting',
    },
    PAYSLIP_ENTRY: '#/payslip',
    REPORT_ENTRY: '#/report',
    COMPANY_SETTING_ENTRY: '#/company_setting',
    COMPANY_SETTING: {
        GENERAL: '#/company_setting/general', // deprecated
        COMPANY_ENTRY: '#/company_setting/company',
        COMPANY: {
            GENERAL: '#/company_setting/company/general',
            HOLIDAY: '#/company_setting/company/holiday',
            JOBS: '#/company_setting/company/jobs',
            DEPARTMENTS: '#/company_setting/company/departments',
        },
        OFFICE_ENTRY: '#/company_setting/office',
        OFFICE: {
            CREATE: '#/company_setting/office/create',
            DETAIL: '#/company_setting/office/{{id}}{{#if tab}}?tab={{tab}}{{/if}}', // &tab=(general|workgroup|holiday)
        },
        LOCATION_ENTRY: '#/company_setting/location',
        LOCATION: {
            CREATE: '#/company_setting/location/create',
            DETAILS: '#/company_setting/location/details',
        },
        WORKGROUPS_ENTRY: '#/company_setting/workgroups',
        WORKGROUPS: {
            CREATE: '#/company_setting/workgroups/create',
            DETAIL: '#/company_setting/workgroups/{{id}}',
            EDIT: '#/company_setting/workgroups/{{id}}/edit',
        },
        WORKGROUP_EMTRY: '#/company_setting/workgroup',
        WORKGROUP: {
            CREATE: '#/company_setting/workgroup/create/{{officeId}}',
            DETAIL: '#/company_setting/workgroup/{{id}}',
        },
        CUSTOM_FIELDS: '#/company_setting/custom-fields',
        GROUP: '#/company_setting/group',
        INTEGRATION_ENTRY: '#/company_setting/integrations',
        INTEGRATION: {
            XERO: '#/company_setting/integrations/xero',
            STAFFANY: '#/company_setting/integrations/staffany',
            SHIFTEE: '#/company_setting/integrations/shiftee',
        },
        SECURITY: '#/company_setting/security',
        BILLING_ENTRY: '#/company_setting/billing',
        BILLING: {
            OVERVIEW: '#/company_setting/billing/overview',
            SWITCH_PLAN: '#/company_setting/billing/switch-plan',
            CHECKOUT: '#/company_setting/billing/checkout',
            PLAN_DETAIL: '#/company_setting/billing/plan-detail',
            SURVEY: '#/company_setting/billing/survey',
        },
    },
    ASK_AI: '#/ask-ai',
    PROFILE: '#/profile',
    ACCOUNT_SETTING: '#/account_setting',
    LOGOUT: '/api/auth/v1/logout?redirect=/#/login',
    SWITCH_ACCOUNT_PAGE: '/switch-account.html',

    SWINGVY_LANDING: {
        HOME: 'https://www.swingvy.com',
        CONTACT_US: 'https://www.swingvy.com/contact',
        PRICING: 'https://www.swingvy.com/pricing',
        PRIVACY: 'https://www.swingvy.com/privacy',
        TOS: 'https://www.swingvy.com/tos',
        DEMO: 'https://www.swingvy.com/demo',
    },
    NATIVE_APP: {
        IOS: '/ios',
        ANDROID: '/android',
    },
};

export default RoutePath;
