/* eslint-disable */
import i18n from 'i18next';
import $ from 'jquery';
import _ from 'underscore';
import queryString from 'query-string';
import { TextUtil } from '@swingvy/frontend-util';
import moment from 'js/moment';
import DataCache from 'js/network/DataCache';
import LocalStorage from 'js/storage/LocalStorage';
import Logger from 'js/helper/Logger';
import { getRequestError, handleSwingvyCoreError } from './requestErrorHandler';

const dataCache = new DataCache();

// https://stackoverflow.com/questions/699941/handle-ajax-error-when-a-user-clicks-refresh
let isPageBeingRefreshed = false;
$(window).on('beforeunload', function () {
    isPageBeingRefreshed = true;
});

const sContext = {
    session: null,
};

class DataProvider {
    constructor(api, arg1, arg2, arg3, arg4) {
        if (!api) return;

        const swingvyApi = {};
        if (arg1 && api.method === 'GET') {
            const { url } = api;
            const lastChar = url.substring(url.length - 1, url.length);
            const hasFormatString = TextUtil.includes(url, '{0}');
            if (lastChar !== '/' && !hasFormatString) {
                swingvyApi.url = TextUtil.format(`${api.url}/{0}`, arg1, arg2, arg3, arg4);
            }
        }

        if (!swingvyApi.url) {
            swingvyApi.url = TextUtil.format(api.url, arg1, arg2, arg3, arg4);
        }
        swingvyApi.method = api.method;

        this.swingvyApi = swingvyApi;
        this.shouldUseCache = true;
        this.param = null;
        this.formData = null;
        this.callback = null;
        this.errorCallback = null;
        this.completeCallback = () => {};
        this.shouldSync = false;
        this.requestHeaders = null;

        this.setParam = this.setParam.bind(this);
        this.setFormData = this.setFormData.bind(this);
        this.addQuery = this.addQuery.bind(this);
        this.addFile = this.addFile.bind(this);
        this.setCallback = this.setCallback.bind(this);
        this.setErrorCallback = this.setErrorCallback.bind(this);
        this.setCompleteCallback = this.setCompleteCallback.bind(this);
        this.setRequestHeaders = this.setRequestHeaders.bind(this);
        this.toSync = this.toSync.bind(this);
        this.useCache = this.useCache.bind(this);
        this.getUrl = this.getUrl.bind(this);
        this.request = this.request.bind(this);
    }

    setParam(param) {
        this.param = param;
        return this;
    }

    setFormData(formData) {
        this.formData = formData;
        return this;
    }

    addQuery(key, value) {
        const { url } = this.swingvyApi;
        value = encodeURIComponent(value);
        if (TextUtil.includes(url, '?')) {
            this.swingvyApi.url = `${url}&${key}=${value}`;
        } else {
            this.swingvyApi.url = `${url}?${key}=${value}`;
        }
        return this;
    }

    addQueryObj(queries) {
        if (_.isEmpty(queries)) return this;

        const { url } = this.swingvyApi;
        this.swingvyApi.url =
            url +
            (TextUtil.includes(url, '?') ? '&' : '?') +
            queryString.stringify(queries, { arrayFormat: 'comma' });
        return this;
    }

    addFile(data) {
        this.formData = new FormData();
        if (typeof data === 'string') {
            const blobBin = window.atob(data.split(',')[1]);
            const array = [];
            for (let i = 0; i < blobBin.length; i++) {
                array.push(blobBin.charCodeAt(i));
            }
            const file = new Blob([new Uint8Array(array)], {
                type: 'image/png',
            });
            this.formData.append('file', file);
        } else if (typeof data === 'object') {
            this.formData.append('file', data);
        }
        return this;
    }

    setCallback(callback) {
        this.callback = callback;
        return this;
    }

    setErrorCallback(errorCallback) {
        this.errorCallback = errorCallback;
        return this;
    }

    setCompleteCallback(completeCallback = () => {}) {
        this.completeCallback = completeCallback;
        return this;
    }

    setRequestHeaders(headers) {
        if (headers) {
            this.requestHeaders = headers;
        }
        return this;
    }

    toSync() {
        this.shouldSync = true;
        return this;
    }

    useCache(shouldUseCache) {
        this.shouldUseCache = shouldUseCache;
        return this;
    }

    getUrl() {
        return this.swingvyApi.url;
    }

    request() {
        const options = {
            cache: false,
            type: this.swingvyApi.method,
            url: this.swingvyApi.url,
            beforeSend: (request) => {
                if (sContext.session) {
                    const { company } = sContext.session;
                    const { user } = sContext.session;
                    request.setRequestHeader('Client-Session', `${company.id}_${user.id}`);
                }
                request.setRequestHeader(
                    'Current-Page-Name',
                    `${location.pathname}${location.hash}`,
                );
                request.setRequestHeader('User-Time-Zone', moment.tz.guess());
                request.setRequestHeader('User-Browser-Language', i18n.language);
                request.setRequestHeader('Company-Id', LocalStorage.get('companyId'));
                if (this.requestHeaders) {
                    Object.keys(this.requestHeaders).forEach((key) => {
                        request.setRequestHeader(key, this.requestHeaders[key]);
                    });
                }

                document.body.style.cursor = 'wait';
            },
            complete: (jqXHR, textStatus) => {
                document.body.style.cursor = 'default';
                this.completeCallback();
            },
        };
        if (this.formData) {
            options.data = this.formData;
            options.processData = false;
            options.contentType = false;
            options.enctype = 'multipart/form-data';
        } else {
            options.data = this.param;
            options.dataType = 'json';
            options.contentType = 'application/json';
            options.cache = false;
            options.async = !this.shouldSync;
        }

        const { errorCallback } = this;
        options.error = (jqXHR, textStatus, thrownError) => {
            if (jqXHR.readyState === 0 && jqXHR.status === 0 && !jqXHR.getAllResponseHeaders()) {
                jqXHR.abort();
                if (isPageBeingRefreshed) {
                    return; // not an error
                }
            }
            // handle error
            try {
                if (handleSwingvyCoreError(jqXHR)) return;
                if (errorCallback) {
                    errorCallback(getRequestError(jqXHR));
                } else {
                    Logger.log({ jqXHR, textStatus, thrownError });
                }
            } catch (e) {
                console.log(e);
                this.completeCallback();
            }
        };

        const { callback } = this;
        options.success = (response, status, xhr) => {
            if (this.swingvyApi.method === 'GET') {
                if (response.data) {
                    dataCache.set(this.swingvyApi, response.data);
                }
            } else {
                dataCache.removeAll();
            }
            try {
                if (response.code === 0 && callback) {
                    callback(response.data);
                }
            } catch (e) {
                console.log(e);
                this.completeCallback();
            }
        };

        if (this.shouldUseCache && this.swingvyApi.method === 'GET') {
            const cachedResult = dataCache.get(this.swingvyApi);
            if (cachedResult) {
                try {
                    callback(cachedResult);
                    options.complete();
                } catch (e) {
                    console.log(e);
                    this.completeCallback();
                }
            } else {
                $.ajax(options);
            }
        } else {
            $.ajax(options);
        }
    }
}

DataProvider.setContext = ({ session }) => {
    sContext.session = session;
};

export default DataProvider;
